import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from 'prop-types'
import Gallery from '../../../../plugins/gallery'

const KikoriHotelGallery = ({ items }) => {
    const data = useStaticQuery(graphql`
    query {
        allFile(filter: {extension: {regex: "/(jpg)|(png)|(gif)/"}, relativeDirectory: {eq: "hotels/kikori/gallery"}}) {
            edges {
                node {
                    childImageSharp {
                        fluid(maxHeight: 1000, maxWidth: 1000, srcSetBreakpoints: [576, 768, 992, 1200]) {
                            src
                            aspectRatio
                            ...GatsbyImageSharpFluid
                        }
                    }
                    base
                }
            }
        }
    }
  `)

    const edges = data.allFile.edges;

    const shuffle = ([...array]) => {
        for (let i = array.lenght -1; i >= 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }
    const tmp2 = shuffle(edges);

    let images = [];
    let thumbs = [];
    let captions = [];
    items.map(item => {
        const result = edges.filter(function(edge, index) {
            if (item.filename == edge.node.base) {
                return true;
            }
        });
        images.push(result[0].node.childImageSharp.fluid.src);
        thumbs.push(result[0].node.childImageSharp.fluid);
        captions.push(item.title);
    })

    return (
        <Gallery images={images} thumbs={thumbs} captions={captions} />
        // <pre>{JSON.stringify(tmp2, null, 4)}</pre>
    )
}

KikoriHotelGallery.propTypes = {
    items: PropTypes.object.isRequired,
}

export default KikoriHotelGallery